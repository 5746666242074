define("discourse/plugins/discourse-gamification/admin/components/admin-create-leaderboard", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _computed, _service, _truthHelpers, _dButton, _ajax, _ajaxError, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _Class;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_dec = (0, _computed.and)("newLeaderboardName"), (_class2 = (_Class = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "toasts", _descriptor3, this);
      _initializerDefineProperty(this, "newLeaderboardName", _descriptor4, this);
      _initializerDefineProperty(this, "loading", _descriptor5, this);
      _initializerDefineProperty(this, "nameValid", _descriptor6, this);
    }
    createNewLeaderboard() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data1 = {
        name: this.newLeaderboardName,
        created_by_id: this.currentUser.id
      };
      return (0, _ajax.ajax)("/admin/plugins/gamification/leaderboard", {
        data: data1,
        type: "POST"
      }).then(leaderboard1 => {
        this.toasts.success({
          duration: 3000,
          data: {
            message: (0, _i18n.default)("gamification.leaderboard.create_success")
          }
        });
        this.router.transitionTo("adminPlugins.show.discourse-gamification-leaderboards.show", leaderboard1.id);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="new-leaderboard-container">
        <Input
          @type="text"
          class="new-leaderboard__name"
          @value={{this.newLeaderboardName}}
          placeholder={{i18n "gamification.leaderboard.name_placeholder"}}
        />
        <DButton
          @label="gamification.create"
          @title="gamification.create"
          class="btn-primary new-leaderboard__create"
          @disabled={{not this.nameValid}}
          @action={{this.createNewLeaderboard}}
        />
        <DButton
          class="new-leaderboard__cancel"
          @label="gamification.cancel"
          @title="gamification.cancel"
          @action={{@onCancel}}
        />
      </div>
    
  */
  {
    "id": "f2zPGTC6",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"new-leaderboard-container\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"new-leaderboard__name\"],[16,\"placeholder\",[28,[32,1],[\"gamification.leaderboard.name_placeholder\"],null]]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"newLeaderboardName\"]]]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"btn-primary new-leaderboard__create\"]],[[\"@label\",\"@title\",\"@disabled\",\"@action\"],[\"gamification.create\",\"gamification.create\",[28,[32,3],[[30,0,[\"nameValid\"]]],null],[30,0,[\"createNewLeaderboard\"]]]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"new-leaderboard__cancel\"]],[[\"@label\",\"@title\",\"@action\"],[\"gamification.cancel\",\"gamification.cancel\",[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@onCancel\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-gamification/admin/components/admin-create-leaderboard.js",
    "scope": () => [_component2.Input, _i18n.default, _dButton.default, _truthHelpers.not],
    "isStrictMode": true
  }), _Class), _Class), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "toasts", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "newLeaderboardName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "nameValid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "createNewLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "createNewLeaderboard"), _class2.prototype)), _class2));
});